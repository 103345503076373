:root {
  --firstColor: #004c13;
  --secondColor: black;
  --thirdColor: #f9d424;
  --fourthColor: #55275f;
}

.Theme--default {
  --firstColor: #004c13;
  --secondColor: black;
  --thirdColor: #f9d424;
  --fourthColor: #55275f;
}

@keyframes strobe {
  0% {
    background-color: var(--firstColor);
  }
  25% {
    background-color: var(--secondColor);
  }
  50% {
    background-color: var(--thirdColor);
  }
  75% {
    background-color: var(--fourthColor);
  }
  100% {
    background-color: var(--firstColor);
  }
}

@keyframes marquee-rtl {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

html {
  box-sizing: border-box;
  font-size: 14px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-size: 1rem;
  margin: 0 5em;
  background-color: white;
  color: black;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

img {
  display: inline-block;
  vertical-align: bottom;
}

p {
  margin: 1em 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.Logo {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 12.5em;
  height: 12.5em;
  pointer-events: none;
  backface-visibility: hidden;
  transition: all 0.5s ease-in-out;
  z-index: 3;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.Header {
  position: fixed;
  top: 0;
  right: 5em;
  left: 5em;
  transform: translateZ(0);
  z-index: 1;
}

.NavLink {
  appearance: none;
  border: 0;
  font-size: 1rem;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  text-decoration: none;
  margin: 0.5em;
  padding: 0.33em 0.5em;
  line-height: 1;
  letter-spacing: 1px;
  border-radius: 5px;
  color: white;
  background-color: black;
  transition: background-color 100ms ease-in-out, color 100ms ease-in-out;
  cursor: pointer;

  &:hover {
    color: black;
    background-color: white;
  }
}

.Marquee {
  width: 100%;
  padding: 1em 0 2em 0;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;

  &__inner {
    display: inline-block;
    text-indent: 0;
    height: 2em;
    padding-left: 100%;
    transform: translateZ(0);
    text-align: center;
    animation: marquee-rtl 10s linear infinite;
  }

  &:hover .Marquee__inner {
    animation-play-state: paused;
  }
}

.Gallery {
  text-align: center;
}

.Img {
  max-width: 50%;
  transition: opacity 0.5s;
  opacity: 0;

  &--loaded {
    opacity: 1;
  }
}

.Page {
  position: fixed;
  top: 0;
  right: 5em;
  bottom: 0;
  left: 5em;
  padding: 1.75em 6em 1.75em 2em;
  visibility: hidden;
  opacity: 0;
  animation: strobe 20s infinite;
  transition: opacity 1s;
  z-index: 2;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &,
  & a {
    color: white;
  }

  &--visible {
    opacity: 1;
    visibility: visible;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    line-height: 1;
    padding: 0.5em 0.75em;
    cursor: pointer;
    transition: color 0.25s;
    font-size: 4rem;
    font-family: "Courier New", Courier, monospace;

    &:hover {
      color: black;
    }
  }
}

.PromoImages {
  margin: 1em 0;
}
.PromoImages__thumb {
  display: inline-block;
  margin: 1em;
  vertical-align: top;
}
.PromoImages__thumb > img {
  max-height: 12.5em;
  max-width: 12.5em;
}

@media (max-width: 800px) {
  body {
    margin: 0;
    font-size: 12px;
  }

  .Header,
  .Page {
    right: 0;
    left: 0;
  }

  .NavLink:after,
  .NavLink:before {
    display: none;
  }

  .Marquee {
    white-space: normal;
    padding: 1em;
  }
  .Marquee__inner {
    display: block;
    text-indent: unset;
    height: auto;
    padding-left: 0;
    text-align: center;
    animation: none;
  }

  .Logo {
    width: 10em !important;
    height: 10em !important;
  }
}

.Video {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.Video iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
}

@media (min-aspect-ratio: 16/9) {
  .Video iframe {
    /* height = 100 * (9 / 16) = 56.25 */
    height: 56.25vw;
  }
}
@media (max-aspect-ratio: 16/9) {
  .Video iframe {
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
  }
}

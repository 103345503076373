:root, .Theme--default {
  --firstColor: #004c13;
  --secondColor: black;
  --thirdColor: #f9d424;
  --fourthColor: #55275f;
}

@keyframes strobe {
  0% {
    background-color: var(--firstColor);
  }

  25% {
    background-color: var(--secondColor);
  }

  50% {
    background-color: var(--thirdColor);
  }

  75% {
    background-color: var(--fourthColor);
  }

  100% {
    background-color: var(--firstColor);
  }
}

@keyframes marquee-rtl {
  0% {
    transform: translate(-100%);
  }

  100% {
    transform: translate(0);
  }
}

html {
  box-sizing: border-box;
  font-size: 14px;
}

*, :before, :after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  color: #000;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  background-color: #fff;
  margin: 0 5em;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
}

img {
  vertical-align: bottom;
  display: inline-block;
}

p {
  margin: 1em 0;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

.Logo {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 3;
  width: 12.5em;
  height: 12.5em;
  transition: all .5s ease-in-out;
  position: fixed;
  top: 50%;
  left: 50%;
}

.Logo > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.Header {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 5em;
  right: 5em;
  transform: translateZ(0);
}

.NavLink {
  appearance: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  cursor: pointer;
  background-color: #000;
  border: 0;
  border-radius: 5px;
  margin: .5em;
  padding: .33em .5em;
  font-size: 1rem;
  line-height: 1;
  text-decoration: none;
  transition: background-color .1s ease-in-out, color .1s ease-in-out;
  display: inline-block;
  position: relative;
}

.NavLink:hover {
  color: #000;
  background-color: #fff;
}

.Marquee {
  white-space: nowrap;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  padding: 1em 0 2em;
  overflow: hidden;
}

.Marquee__inner {
  text-indent: 0;
  text-align: center;
  height: 2em;
  padding-left: 100%;
  animation: 10s linear infinite marquee-rtl;
  display: inline-block;
  transform: translateZ(0);
}

.Marquee:hover .Marquee__inner {
  animation-play-state: paused;
}

.Gallery {
  text-align: center;
}

.Img {
  opacity: 0;
  max-width: 50%;
  transition: opacity .5s;
}

.Img--loaded {
  opacity: 1;
}

.Page {
  visibility: hidden;
  opacity: 0;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  padding: 1.75em 6em 1.75em 2em;
  transition: opacity 1s;
  animation: 20s infinite strobe;
  position: fixed;
  inset: 0 5em;
  overflow-y: auto;
}

.Page, .Page a {
  color: #fff;
}

.Page--visible {
  opacity: 1;
  visibility: visible;
}

.Page__close {
  cursor: pointer;
  padding: .5em .75em;
  font-family: Courier New, Courier, monospace;
  font-size: 4rem;
  line-height: 1;
  transition: color .25s;
  position: absolute;
  top: 0;
  right: 0;
}

.Page__close:hover {
  color: #000;
}

.PromoImages {
  margin: 1em 0;
}

.PromoImages__thumb {
  vertical-align: top;
  margin: 1em;
  display: inline-block;
}

.PromoImages__thumb > img {
  max-width: 12.5em;
  max-height: 12.5em;
}

@media (width <= 800px) {
  body {
    margin: 0;
    font-size: 12px;
  }

  .Header, .Page {
    left: 0;
    right: 0;
  }

  .NavLink:after, .NavLink:before {
    display: none;
  }

  .Marquee {
    white-space: normal;
    padding: 1em;
  }

  .Marquee__inner {
    text-indent: unset;
    text-align: center;
    height: auto;
    padding-left: 0;
    animation: none;
    display: block;
  }

  .Logo {
    width: 10em !important;
    height: 10em !important;
  }
}

.Video {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
}

.Video iframe {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (aspect-ratio >= 16 / 9) {
  .Video iframe {
    height: 56.25vw;
  }
}

@media (aspect-ratio <= 16 / 9) {
  .Video iframe {
    width: 177.78vh;
  }
}
/*# sourceMappingURL=index.737b1e9f.css.map */
